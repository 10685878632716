import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import useReactRouter from 'use-react-router'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { NodeGroupSelector } from '../capi/details/node-groups/CapiClustersNodeGroupsOverview'
import { Phase } from '../capi/machine-deployment/model'

export const getNodeGroupStatus = (phase) => {
  switch (phase) {
    case Phase.Running:
      return 'ok'
    case Phase.Failed:
      return 'fail'
    case Phase.ScalingUp:
      return 'pause'
    case Phase.ScalingDown:
      return 'pause'
    case Phase.Deleting:
      return 'error'
    default:
      return 'unknown'
  }
}

export default function NodeGroupNameCell({ item }: GridCellProps<NodeGroupSelector>) {
  return <SimpleLink>{item.metadata.name}</SimpleLink>
}
